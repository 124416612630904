import * as React from 'react'
import { useEffect } from 'react'

import Link from 'next/link'

interface ISmoothScrollProps {
  name: React.ReactNode;
  href: string;
  selected: boolean;
  onClick: () => void;
}

const SmoothScroll: React.FC<ISmoothScrollProps> = (
  props: ISmoothScrollProps,
) => {
  const { name, href, selected, onClick } = props
  const linkRef = React.useRef<HTMLAnchorElement>(null)

  const handleClick = () => {
    onClick()
  }

  useEffect(() => {
    const linkElement = linkRef.current
    if (linkElement) {
      linkElement.addEventListener('click', (e) => {
        if (href.startsWith('#') && !href.startsWith('#http')) {
          e.preventDefault()
          const target = document.getElementById(href.substring(1))
          if (target) {
            window.scrollTo({
              top: target.offsetTop,
              behavior: 'smooth',
            })
          }
        }
      })
    }
  }, [href])

  //   const active = selected ? 'block' : 'hidden'
  const styled =
    selected == true
      ? 'py-4 md:py-1 px-2 uppercase border-b-4 border-secondary-500 font-semibold rounded hover:text-secondary-500 transition duration-300 hover:bg-primary-600 hover:border-primary-300'
      : 'py-4 md:py-1 px-2 uppercase rounded transition duration-300 font-semibold drop-shadow-lg t-shadow hover:text-secondary-600 hover:bg-secondary-200 hover:bg-opacity-40 hover:border-b-2 hover:border-secondary-500'

  return (
    <>
      <Link className={styled} ref={linkRef} href={href} onClick={handleClick} scroll={false}>
        {name}
      </Link>
    </>
  )
}

export default SmoothScroll
