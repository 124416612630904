import React from 'react'
import Markdown from 'react-markdown'
import rehypeRaw from 'rehype-raw'
import remarkGfm from 'remark-gfm'

interface IProps {
  content: string
  className?: string
}

const MDContent = (props: IProps) => {
  const { content, className } = props
  const newClass = className ? className : 'md-content'
  return (
    <div className={newClass}>
      <Markdown remarkPlugins={[remarkGfm]} rehypePlugins={[rehypeRaw]}>
        {content}
      </Markdown>
    </div>
  )
}

export default MDContent
