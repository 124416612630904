import Config from './site'

const { site, social } = Config

const LinkList = [
  {
    id: 1,
    title: 'Legal',
    diffClass: 'md:col-start-7',
    list: [
      {
        id: 1,
        href: '/terminos',
        text: 'Términos y condiciones',
      },
      {
        id: 2,
        href: '/privacidad',
        text: 'Políticas de privacidad',
      },
    ],
  },
  {
    id: 2,
    title: 'Nosotros',
    diffClass: '',
    list: [
      {
        id: 1,
        href: '/acerca-de',
        text: 'Nosotros',
      },
      {
        id: 2,
        href: `/contacto`,
        text: 'Contacto',
      },
      {
        id: 3,
        href: '/blog',
        text: 'Blog',
      },
    ],
  },
]

const SocialIcons = [
  {
    id: 1,
    href: social.facebook,
    text: 'facebook',
    label: 'Síguenos en facebook'
  },
  {
    id: 2,
    href: social.twitter,
    text: 'twitter',
    label: 'Síguenos en twitter'
  },
  {
    id: 3,
    href: social.linkedin,
    text: 'linkedin',
    label: 'Síguenos en linkedin'
  },
]

export { LinkList, SocialIcons }
