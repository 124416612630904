const Menu = {
  main: [
    { id: '1', name: 'Inicio', link: '#home' },
    { id: '2', name: 'Psicóloga', link: '#psi' },
    { id: '3', name: 'Valores', link: '#valores' },
    { id: '4', name: 'Terapias', link: '#terapias' },
    { id: '5', name: 'Información', link: '#contacto' },
  ],
  pages: [
    { id: '1', name: 'Inicio', link: '/' },
    { id: '2', name: 'Contacto', link: '/contacto' },
    { id: '3', name: 'Blog', link: '/blog' },
  ],
}

export default Menu
