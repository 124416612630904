class TimeSettings {
  private static instance: TimeSettings
  options: Record<string, unknown>
  locales: string

  private constructor() {
    this.options = {
      weekday: 'long',
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      timeZone: 'UTC',
    }
    this.locales = 'es-MX'
  }
  public static getInstance(): TimeSettings {
    if (!TimeSettings.instance) {
      TimeSettings.instance = new TimeSettings()
    }
    return TimeSettings.instance
  }
  public toLongLocal(date: Date) {
    if (date == undefined) return
    const converted = new Date(date)
    return converted.toLocaleDateString(this.locales, this.options)
  }
  public toShortLocal(date: Date) {
    if (date == undefined) return
    const converted = new Date(date)
    return converted.toLocaleDateString(this.locales)
  }
  public formatToPost(dateStr: string) {
    const dateObj = new Date(dateStr).toUTCString()
    const dateToShow = dateObj.split(' ').slice(0, 4).join(' ')

    return dateToShow
  }
}
const Time = TimeSettings.getInstance()
export default Time
