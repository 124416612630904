import {
  AcademicCapIcon,
  ArrowDownIcon,
  ArrowDownOnSquareIcon,
  ArrowPathIcon,
  ArrowTrendingUpIcon,
  BookmarkSlashIcon,
  BookOpenIcon,
  BugAntIcon,
  CursorArrowRaysIcon,
  FaceFrownIcon,
  FireIcon,
  HandThumbDownIcon,
  LightBulbIcon,
  NoSymbolIcon,
  SignalSlashIcon,
  SpeakerXMarkIcon,
  XMarkIcon,
} from '@heroicons/react/24/solid'

export const REASONS = [
    {
        icon: CursorArrowRaysIcon,
        text: "⁠Cambio repentino de la conducta",
      },
      {
        icon: BookmarkSlashIcon,
        text: "Empeoramiento significativo en el rendimiento escolar",
      },
      {
        icon: BookOpenIcon,
        text: "Dificultades en la lectura y en la escritura",
      },
      {
        icon: ArrowDownOnSquareIcon,
        text: "Deterioro repentino del rendimiento escolar",
      },
      {
        icon: SpeakerXMarkIcon,
        text: "Aislamiento de sus compañeros de clase, familiares o amigos",
      },
      {
        icon: ArrowTrendingUpIcon,
        text: "Hiperactividad",
      },
      {
        icon: ArrowPathIcon,
        text: "Impulsividad excesiva",
      },
      {
        icon: FaceFrownIcon,
        text: "Tristeza",
      },
      {
        icon: ArrowDownIcon,
        text: "Decaimiento",
      },
      {
        icon: FireIcon,
        text: "Irritabilidad",
      },
      {
        icon: XMarkIcon,
        text: "Problemas con el control de los esfínteres",
      },
      {
        icon: NoSymbolIcon,
        text: "Negativa a comer",
      },
      {
        icon: BugAntIcon,
        text: "Miedos",
      },
      {
        icon: FaceFrownIcon,
        text: "Pesadillas",
      },

      {
        icon: AcademicCapIcon,
        text: "Problemas de aprendizaje o de atención",
      },
      {
        icon: SignalSlashIcon,
        text: "⁠Llamadas de atención de los tutores del colegio",
      },
      {
        icon: HandThumbDownIcon,
        text: "Falta de atención",
      },
      {
        icon: FaceFrownIcon,
        text: "Ansiedad ante la separación con los padres",
      },
      {
        icon: LightBulbIcon,
        text: "Muchas 'manías' u obsesiones",
      },
]

