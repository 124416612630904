'use client'

import react from 'react'

import { FC, useState } from 'react'
import { ShieldExclamationIcon, PaperAirplaneIcon } from '@heroicons/react/24/solid'
import { Toast } from 'flowbite-react'
import { useForm } from 'react-hook-form'
import Link from 'next/link'

import { FormData } from './types'
import { SendEmail } from '@/lib/email'
import Config from '@/data/site'
import ErrorMessage from './ErrorMessage'

const Form: FC = () => {
  const [showToast, setShowToast] = useState(false)
  const [toastMessage, setToastMessage] = useState('')
  const [showErrorToast, setShowErrorToast] = useState(false)
  const [toastErrorMessage, setToastErrorMessage] = useState('')
  const { contact } = Config

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>()

  const onSubmit = async (data: FormData) => {
    const { sent: sent, message: msg } = await SendEmail(contact, data)
    if (sent) {
      setToastMessage(msg)
      setShowToast(true)
    } else {
      setToastErrorMessage(msg)
      setShowErrorToast(true)
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="mb-5 relative">
        <input
          id="name"
          type="text"
          placeholder=""
          autoComplete="on"
          className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-primary-500 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-primary-500 focus:outline-none focus:ring-0 focus:border-primary-500 focus:border-2 peer"
          {...register('name', { required: 'El nombre es requerido' })}
        />
        <label
          htmlFor="name"
          className="absolute bg-neutral-100 text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-primary-600 peer-focus:dark:text-primary-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
        >
          Nombre Completo
        </label>
        {errors.name && <ErrorMessage message={errors.name.message} />}
      </div>
      <div className="mb-5 relative">
        <input
          id="email"
          type="email"
          autoComplete="on"
          placeholder="usuario@mail.com"
          className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-primary-500 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-primary-500 focus:outline-none focus:ring-0 focus:border-primary-500 focus:border-2 peer"
          {...register('email', {
            required: 'El correo es requerido',
            pattern: {
              value: /^[^@ ]+@[^@ ]+\.[^@ .]{2,}$/,
              message: 'Correo inválido',
            },
          })}
        />
        <label
          htmlFor="email"
          className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-neutral-100 dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-primary-600 peer-focus:dark:text-primary-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
        >
          Correo electrónico
        </label>
        {errors.email && <ErrorMessage message={errors.email.message} />}
      </div>
      <div className="mb-5 relative">
        <textarea
          id="message"
          rows={4}
          placeholder=""
          className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-primary-500 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-primary-500 focus:outline-none focus:ring-0 focus:border-primary-500 focus:border-2 peer"
          {...register('message', { required: 'Debe ingresar un mensaje' })}
        ></textarea>
        <label
          htmlFor="message"
          className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-neutral-100 dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-primary-600 peer-focus:dark:text-primary-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
        >
          Ingrese su mensaje
        </label>
        {errors.message && <ErrorMessage message={errors.message.message} />}
      </div>
      <div className="md:flex relative">
        <label className="grid grid-cols-1 md:grid-cols-2 ">
          <input
            {...register('terminos', { required: 'Debe aceptar los términos y condiciones' })}
            type="checkbox"
            value="F"
            className="sr-only peer"
          />
          <div className="relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-primary-300 dark:peer-focus:ring-primary-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-primary-600"></div>
          <span className="ms-3 text-sm font-medium text-gray-900 dark:text-gray-300 absolute left-20">
            Acepto{' '}
            <Link
              href={'/terminos'}
              target="_blank"
              className="text-secondary-500 hover:text-primary-500 hover:underline"
            >
              Términos y Condiciones
            </Link>
          </span>
        </label>
        {errors.terminos && (
          <ErrorMessage className="w-full py-5" message={errors.terminos.message} />
        )}
        <div className=" py-12">
          <button
            type="submit"
            className="py-3 absolute right-0 text-base font-sans text-center text-white bg-primary-700 rounded-lg focus:ring-4 focus:ring-primary-200 dark:focus:ring-primary-900 hover:bg-primary-800"
          >
            <span className="text-sm w-full px-4">Enviar mensaje</span>
          </button>
        </div>
      </div>
      {showToast && (
        <Toast className="fixed top-10 right-5 z-50">
          <div className="inline-flex items-center justify-center flex-shrink-0 w-8 h-8 text-green-500 bg-green-100 rounded-lg dark:bg-green-800 dark:text-green-200">
            <PaperAirplaneIcon
              className="w-5 h-5"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              viewBox="0 0 24 24"
            />
          </div>
          <div className="ml-3 text-sm font-normal">{toastMessage}</div>
          <Toast.Toggle
            className="ms-auto -mx-1.5 -my-1.5 bg-white text-green-400 hover:text-green-900 rounded-lg focus:ring-2 focus:ring-green-300 p-1.5 hover:bg-green-100 inline-flex items-center justify-center h-8 w-8 dark:text-green-500 dark:hover:text-white dark:bg-green-800 dark:hover:bg-green-700"
            onDismiss={() => setShowToast(false)}
          />
        </Toast>
      )}
      {showErrorToast && (
        <Toast className="fixed top-10 right-5 z-50">
          <div className="inline-flex items-center justify-center flex-shrink-0 w-8 h-8 text-red-500 bg-red-100 rounded-lg dark:bg-red-800 dark:text-red-200">
            <ShieldExclamationIcon
              className="w-5 h-5"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              viewBox="0 0 24 24"
            />
          </div>
          <div className="ml-3 text-sm font-normal">{toastErrorMessage}</div>
          <Toast.Toggle
            className="ms-auto -mx-1.5 -my-1.5 bg-white text-red-400 hover:text-red-900 rounded-lg focus:ring-2 focus:ring-red-300 p-1.5 hover:bg-red-100 inline-flex items-center justify-center h-8 w-8 dark:text-red-500 dark:hover:text-white dark:bg-red-800 dark:hover:bg-red-700"
            onDismiss={() => setShowErrorToast(false)}
          />
        </Toast>
      )}
    </form>
  )
}

export default Form
