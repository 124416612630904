import { Open_Sans } from "next/font/google";

const Fonts = Open_Sans({
  weight: ['300', '700'],
  style: ['normal', 'italic'],
  subsets: ['latin'],
  display: 'swap',
  variable: '--font-open-sans',
})

export default Fonts
