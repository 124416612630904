import React from 'react'

const Schedule = () => {
  return (
    <div className="mx-auto max-w-[45rem] my-10 text-wrap md:text-balance rounded-xl bg-primary-200 bg-opacity-15 p-5 md:p-14 shadow-md hover:shadow-xl">
      <h3 className="w-full pb-4 text-secondary-500 text-center uppercase text-bold font-sans tracking-widest text-2xl">
        Nuestros horarios
      </h3>
      <div className="w-full text-lg grid grid-cols-2 ">
        <div className="relative flex flex-col py-4">
          <span className="mx-auto font-bold py-2 ">De lunes a viernes</span>
          <span className="mx-auto ">10:00 am a 8:00 pm</span>
        </div>
        <div className="relative flex flex-col py-4">
          <span className="mx-auto font-bold py-2 ">Sábados</span>
          <span className="mx-auto ">8:00 am a 8:00 pm</span>
        </div>
      </div>
    </div>
  )
}

export default Schedule
