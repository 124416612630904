import axios from 'axios'

import { FormData } from '@/components/contact/types'

interface IProps {
  endpoint: string
  email: string
  cc: string
  subject: string
  formcode: string
}

export const BuildPayload = (props: IProps, data: FormData) => {
  const { email, cc, subject } = props

  const sub = window.atob(subject)
  return {
    subject: `${sub} - correo de ${data.email}`,
    fullname: data.name,
    copy: window.atob(cc),
    mail: window.atob(email),
    message: data.message,
    replyto: data.email,
  }
}

export const SendEmail = async (
  contact: IProps,
  data: FormData,
): Promise<{ sent: boolean; message: string }> => {
  const payload = BuildPayload(contact, data)
  return API(contact.endpoint)
    .post(`f/${contact.formcode}`, payload)
    .then(() => {
      return { sent: true, message: 'Mensaje enviado exitosamente!' }
      // handleOnSuccess('El mensaje ha sido enviado!')
    })
    .catch((error) => {
      if (error.response) {
        // Request made and server responded
        console.log(error.response.data)
        console.log(error.response.status)
        console.log(error.response.headers)
      } else if (error.request) {
        // The request was made but no response was received
        console.log(error.request)
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log('Error', error.message)
      }
      return { sent: false, message: 'Ocurrió un error. Intente más tarde por favor!' }
      // handleOnError('Hubo un error de red. Intente más tarde por favor!')
    })
}

export const API = (endpoint: string) => {
  return axios.create({
    baseURL: endpoint,
  })
}
