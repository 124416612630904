import React from 'react'
import { TagIcon } from '@heroicons/react/24/solid'
import Link from 'next/link'

interface IProps {
  text: string
  link: string
}

const Tag = (props: IProps) => {
  const { text, link } = props
  return (
    <Link
      href={link}
      className="inline-flex mx-0.5 text-sm group font-bold  text-primary-100 t-shadow-dark hover:t-shadow transition-colors pr-2 py-0.5 bg-primary-400 hover:cursor-pointer rounded hover:bg-secondary-200"
    >
      <TagIcon className=" h-5 w-5 fill-primary-100 mx-1 group-hover:fill-primary-800" />
      <span className="group-hover:font-semibold group-hover:text-primary-800">{text}</span>
    </Link>
  )
}

export default Tag
