const basePath = '/images/values'

export const VALUES = [
    {
        image: `${basePath}/mom_170x170.jpg`,
        title: "Amor",
        description: "El amor maternal es único, pero no pasa nada si intentamos imitarlo"
    },
    {
        image: `${basePath}/kids_170x170.jpg`,
        title: "Honestidad",
        description: "Parece un valor perdido, empezamos buscándola en nosotros mismos."
    },
    {
        image: `${basePath}/aprendizaje_170x170.jpg`,
        title: "Aprendizaje",
        description: "Producto de la experiencia, y ésta es siempre personal y, por tanto, no puede ser calificada."
    },
]