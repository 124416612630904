import React from 'react'

import Header from './Header'
import Body from './Body'
import Warning from './Warning'
import InputMessage from './InputMessage'

interface IProps {
  id: string
  onClose: () => void
}

const ChatBox = (props: IProps) => {
  const { id, onClose } = props

  return (
    <div
      id={id}
      className="bg-green-600 fixed bottom-32 md:bottom-16 right-4 z-20 md:right-32 rounded-lg justify-center h-2/3"
    >
      {/* <!-- First Layer --> */}
      <div className="w-[22rem] sm:w-[25rem] h-full rounded-lg">
        {/* <!--Second Layer --> */}
        <div className="h-full bg-green-600 rounded-3xl ">
          <Header title="Clínica Infantil" message="Generalmente respondemos en pocos minutos" />
          {/* <!-- Chatbox-body --> */}
          <div className="bg-neutral-200 relative h-5/6 bottom-0 bg-whatsapp">
            <Body message="¿En que podemos ayudarle?" />
            <InputMessage />
          </div>
          <button
            className="w-8 h-8 md:w-12 md:h-12 rounded-full flex items-center justify-center bg-neutral-900 md:bg-green-500 text-white cursor-pointer absolute right-2 md:right-4 top-2 sm:-right-14 sm:bottom-0 z-20"
            onClick={onClose}
          >
            <p className="text-3xl"> &times; </p>
          </button>
        </div>
      </div>
    </div>
  )
}

export default ChatBox
