import { SEO, Config } from '@/data/index'
import type { Metadata } from 'next'

const url = process.env.NODE_ENV == 'production' ? Config.site.URL : 'http://localhost:3000/'

const metadata: Metadata = {
  metadataBase: new URL(url),
  title: Config.site.title,
  description: Config.site.description,
  keywords: SEO.Keywords,
  authors: SEO.Authors,
  creator: SEO.Creator,
  icons: SEO.IconSEO.icons,
  alternates: SEO.Alternates,
  category: 'technology',
  openGraph: SEO.OpenGraph,
}

export default metadata