import React from 'react'
import Image from 'next/image'

interface IProps {
  title: string
  message: string
}

const Header = (props: IProps) => {
  const { title, message } = props
  return (
    <div className="flex gap-3 flex-wrap py-3 px-4 rounded-t-md">
      {/* <!--Group of Images --> */}
      <div className="flex items-center [&>*]:w-[2.7rem] [&>*]:h-[2.7rem] [&>*]:rounded-full [&>*]:bg-rose-400 [&>*]:p-0.5 [&>*]:-ml-2 [&>*:hover]:z-20 [&>*:hover]:scale-105 [&>*>img]:h-full [&>*>img]:w-full [&>*>img]:rounded-full [&>*>img]:object-cover transition-all duration-300">
        {/* <!--Image 1 --> */}
        <div>
          <Image
            width={100}
            height={152}
            src="/images/whatsapp_45x39.png"
            alt="Logo"
            title="Whatsapp Logo"
            priority={true}
            loading="eager"
          />
        </div>
      </div>
      {/* <!--Names and description --> */}
      <div className="text-white">
        <h2 className="mb-1 font-semibold">{title}</h2>
        <p className="text-xs">{message}</p>
      </div>
    </div>
  )
}

export default Header
