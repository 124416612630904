import { Config } from '@/data/index'
import { EnvelopeIcon, MapPinIcon, PhoneArrowUpRightIcon } from '@heroicons/react/24/solid'

export const Title = 'Información de Contacto'

export const Description =
  'Si desea más información sobre nuestros servicios, cuenta con las siguientes opciones:'

const { contact } = Config
export const Details = [
  {
    icon: PhoneArrowUpRightIcon,
    name: 'Teléfono',
    content: contact.phonemask,
    link: contact.phonelink,
  },
  {
    icon: EnvelopeIcon,
    name: 'Correo Electrónico',
    content: atob(contact.email),
    link: `mailto:${atob(contact.email)}`,
  },
  {
    icon: MapPinIcon,
    name: 'Dirección',
    content: contact.address,
    link: 'https://maps.app.goo.gl/DokPfmMsNA5f6YcS7',
  },
]
