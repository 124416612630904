import React from 'react'
import Image from 'next/image'

interface IProps {
  message: string
}
const Body = (props: IProps) => {
  const { message } = props

  return (
    <div className="px-4 py-8">
      <div className="flex gap-3">
        {/* <!-- Icon image --> */}
        <div className="w-12 rounded-full p-0.5">
          <Image
            width={100}
            height={152}
            src="/logo_100x.png"
            alt="Logo"
            title="Logo de la Clínica De Diagnóstico Y Tratamiento Infantil"
            priority={true}
            loading="eager"
          />
        </div>
        {/* <!-- Text Message --> */}
        <div className="text-sm p-5 w-[75%] bg-neutral-600 text-slate-100 rounded-lg relative before:absolute before:content-[''] before:w-3 before:h-3 before:bg-slate-600 before:rotate-45 before: before:-left-1 before:top-4">
          <p>{message}</p>
        </div>
      </div>
    </div>
  )
}

export default Body
