import React from 'react'

import { Time } from '@/lib/index'
import Tag from './Tag'

interface IProps {
  author: string
  date: Date
  tags: string[]
}

const ArticleInfo = (props: IProps) => {
  const { author, date, tags } = props
  const publishDate = Time.toShortLocal(date)
  const longDate = Time.toLongLocal(date)
  const TagList = tags.map((item: string, idx: number) => {
    return <Tag key={idx} text={item} link={`/tags/${item}`} />
  })
  return (
    <section id="post-details" className="mx-auto max-w-[58rem] text-wrap md:text-balance">
      <div className="flex flex-row border-b-2 border-primary-500">
        <div className="basis-1/2 p-2">{author}</div>
        <div className="basis-1/2 text-right p-2">
          <span className="uppercase font-bold text-xs">Publicado:</span>{' '}
          <time dateTime={longDate}>{publishDate}</time>
        </div>
      </div>
      <div className="p-2 min-w-56 text-left ">{TagList}</div>
    </section>
  )
}

export default ArticleInfo
