import Config from './site'
import type { Metadata } from 'next'

const siteURL = Config.site.URL
const iconPath = '/favicons/'
const defaultIcon = `${iconPath}favicon-16x16.jpg`
const appleIcon = `${iconPath}apple-touch-icon.jpg`
const androidIcon = `${iconPath}android-chrome-`

export const IconSEO = {
  icons: {
    icon: [
      { url: `${defaultIcon}` },
      new URL(`${defaultIcon}`, siteURL),
      {
        url: `${defaultIcon}`,
        media: '(prefers-color-scheme: dark)',
      },
    ],
    shortcut: [`${defaultIcon}`],
    apple: [
      { url: `${appleIcon}` },
      {
        url: `${appleIcon}`,
        sizes: '180x180',
        type: 'image/png',
      },
    ],
    android: [
      {
        url: `${androidIcon}192x192.jpg`,
        sizes: '192x192',
        type: 'image/png',
      },
      {
        url: `${androidIcon}512x512.jpg`,
        sizes: '512x512',
        type: 'image/png',
      },
    ],
    other: [
      {
        rel: 'apple-touch-icon-precomposed',
        url: `${appleIcon}`,
      },
    ],
  },
}

export const Authors = [
  { name: 'Iver' },
  { name: 'Ivan', url: 'https://iver.mx' },
  { name: 'RomikyaLabs', url: 'https://romikya.mx' },
]

export const Creator = 'Ivan Jaimes'

export const Keywords = ['Psicóloga Infantil', 'Terapia Infantil', 'Psicología Infantil']

export const Alternates = {
  canonical: siteURL,
  languages: {
    'es-MX': `${siteURL}/es-MX`,
  },
  types: {
    'application/rss+xml': `${siteURL}/rss`,
  },
}

export const OpenGraph = {
  url: siteURL,
  type: 'website',
}

export const DefaultMetadata: Metadata = {
  metadataBase: new URL(siteURL),
  title: Config.site.title,
  description: Config.site.description,
  keywords: Keywords,
  authors: Authors,
  creator: Creator,
  icons: IconSEO.icons,
  alternates: Alternates,
  category: 'technology',
  openGraph: OpenGraph,
}
