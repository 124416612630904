'use client'
import React from 'react'
import { motion, useScroll } from 'framer-motion'

import { MDContent } from '@/components/markdown'
import Title from './Title'

interface IProps {
  title?: string
  content: string
  className?: string
}

const Content = ({ title, content, className }: IProps) => {
  const { scrollYProgress } = useScroll()
  const headMargin = title ? 'mt-36' : ''
  const newClass = className ? className : 'md-content'
  return (
    <section className={`container ${headMargin} text-justify`}>
      <motion.div className="progress-bar" style={{ scaleX: scrollYProgress }} />
      {title && <Title text={title} />}
      <div className="mx-auto max-w-[58rem] text-wrap md:text-balance">
        <MDContent className={`${newClass} md:px-16`} content={content} />
      </div>
    </section>
  )
}

export default Content
