import React from 'react'

interface IProps {
  text: string
}

const Title = (props: IProps) => {
  const { text } = props
  return <h1 className="text-center text-4xl font-medium text-primary-900 mb-8">{text}</h1>
}

export default Title
