'use client'
import * as React from 'react'
import { useState } from 'react'
import { useMediaQuery } from 'usehooks-ts'

import useHasScrolled from '@/lib/hooks'
import { ChatBox } from './sections'
import builtWALink from './sections/code'

const Whatsapp = () => {
  const isScrolled = useHasScrolled(20)
  const [display, setDisplay] = useState(false)
  const matches = useMediaQuery('(min-width: 768px)')

  const show = isScrolled ? '' : 'whats-btn-show'

  const handleDisplaying = async () => {
    if (matches) {
      setDisplay(!display)
    } else {
      const link = await builtWALink()
      window.open(link, '_blank', 'noopener,noreferrer')
    }
  }

  return (
    <>
      <button
        className={`whats-btn transition duration-150 ease-in-out ${show}`}
        type="button"
        role="button"
        rel="noreferrer"
        data-modal-target="static-modal"
        data-modal-toggle="static-modal"
        onClick={handleDisplaying}
      >
        <span className="text-transparent">Text</span>
      </button>

      {display ? <ChatBox id="chat" onClose={handleDisplaying} /> : ''}
    </>
  )
}

export default Whatsapp
