import React from 'react'

interface IProps {
  image: string
  text: string
}

const HeroTitle = (props: IProps) => {
  const { image, text } = props
  return (
    <div className="mt-16">
      <section
        className="relative bg-cover bg-center bg-no-repeat py-16 md:py-48 after:absolute after:inset-0"
        style={{ backgroundImage: `url(${image})` }}
      >
        <div className="container relative z-10">
          <h1 className="text-center py-14 text-xl md:text-6xl font-semibold text-primary-100">
            <span className="bg-primary-950 bg-opacity-60 px-2 py-5 md:px-10 rounded-md t-shadow">
              {text}
            </span>
          </h1>
        </div>
        <div className="absolute bottom-0 left-0 right-0 top-0 h-full w-full overflow-hidden "></div>
      </section>
    </div>
  )
}

export default HeroTitle
