import { useState, useEffect } from 'react'

const useHasScrolled = (distance = 10) => {
  const [scroll, setScroll] = useState(true)

  // running on mount
  useEffect(() => {
    const onScroll = () => {
      const scrollCheck = window.scrollY < distance
      if (scrollCheck !== scroll) {
        setScroll(scrollCheck)
      }
    }

    // setting the event handler from web API
    document.addEventListener('scroll', onScroll)

    // cleaning up from the web API
    return () => {
      document.removeEventListener('scroll', onScroll)
    }
  }, [scroll, setScroll, distance])

  return scroll
}

export default useHasScrolled
