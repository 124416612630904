'use client'
import React from 'react'
import { MoonIcon, SunIcon } from '@heroicons/react/24/solid'

import { handleDarkMode } from '@/lib/index'

const DarkMode = () => {
  return (
    <div
      onClick={() => handleDarkMode()}
      className="group ml-2 flex h-10 w-10 cursor-pointer items-center justify-center rounded-full border border-slate-100 bg-white transition-colors hover:border-transparent hover:bg-accent focus:border-transparent focus:bg-accent dark:border-transparent dark:bg-white/[.15] dark:hover:bg-accent"
    >
      <MoonIcon className="dark-mode-light h-4 w-4 fill-slate-700 transition-colors group-hover:fill-white group-focus:fill-white dark:hidden" />

      <SunIcon className='dark-mode-dark hidden h-4 w-4 fill-slate-700 transition-colors group-hover:fill-white group-focus:fill-white dark:block dark:fill-white' />
    </div>
  )
}

export default DarkMode
